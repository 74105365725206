import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { ContactForm } from '../forms'

const ContactPageTemplate = (props) => {
  const { title, meta_title, meta_description } = props

  return (
    <div>
      <Helmet>
        <title>{meta_title}</title>
        <meta name='description' content={meta_description} />
      </Helmet>
      <section className='hero is-bold is-medium is-primary'>
        <div className='hero-body'>
          <div className='container pb-6'>
            <h1 className='title has-text-centered'> We'd love to hear from you</h1>
            <span />
            <h2 className='subtitle has-text-centered'> Our team is ready to answer any questions you have.</h2>
            <span />
          </div>
          <div className='container pt-3'>
            <div className='columns'>
              <div className='column is-offset-1'>
                <img src='/img/contact.png' width='446.4' height='268.8'/>
              </div>
              <div className='column'>
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

ContactPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
}

export default ContactPageTemplate
